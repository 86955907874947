
import { Machine, Transfert, TransfertDA, Utilisateur } from "@/store/types"
import axios from "axios"
import Vue from "vue"
import Saisirdate from "../components/SaisirDate.vue"
import NonAcces from "../components/NonAcces.vue"
import { dateFromString, dateVersChaine, sqlDate, variables } from "../plugins/variables"

const aujourdhui = dateVersChaine(new Date())

export default Vue.extend({
  name: "TransfertLaitier",
  components: { Saisirdate, NonAcces },
  data() {
    return {
      unsubscribe: null as any,
      aujourdhui: aujourdhui,
      transferts: [] as Transfert[],
      affichageTransfert: [] as Transfert[],
      utilisateurs: [] as Utilisateur[],
      dictionnaire: [] as string[],
      machines: [] as Machine[]
    }
  },
  methods: {
    initialiser() {
      var url = this.$store.state.ajaxurl + "TransfertLaitier.php"
      axios
        .get(url, {
          params: {
            action: "TRANSFERT",
            aujourdhui: sqlDate(new Date(this.aujourdhui))
          }
        })
        .then(response => {
          if (response.data.transferts) {
            this.transferts = response.data.transferts.map((t: Transfert) => {
              return new Transfert(t)
            })
            this.afficherLesTransferts(this.transferts)
          }
          if (response.data.utilisateurs) {
            this.utilisateurs = response.data.utilisateurs
          }
          if (response.data.machines) {
            this.machines = response.data.machines.map((m: Machine) => {
              return new Machine(m)
            })
          }
          this.choixLangue(this.$store.state.langue)
        })
    },
    changerJour(nbJour: number) {
      const date = dateFromString(this.aujourdhui)
      this.aujourdhui = dateVersChaine(new Date(date.getFullYear(), date.getMonth(), date.getDate() + nbJour, 12))
      this.initialiser()
    },
    trouveUtilisateur(ut_id: number) {
      return this.utilisateurs.find((u: Utilisateur) => ut_id == u.ut_id)
    },
    trouveMachine(sm_id: number) {
      const uneMachine = this.machines.find((m: Machine) => m.getId() == sm_id)
      if (uneMachine !== undefined) {
        return uneMachine.getDesignation()
      } else {
        return "Non définie"
      }
    },
    afficherLesTransferts(lesTransfert: Transfert[]) {
      this.affichageTransfert = []
      for (const unTransfert of lesTransfert) {
        this.affichageTransfert.push(unTransfert)
      }
    },
    traductionCode(code: string, AD: string, unTransferDa: TransfertDA) {
      const leTransfert = new TransfertDA(unTransferDa)
      if (this.$store.state.langue == "AL") {
        switch (code) {
          case "Arrossage PS":
            if (AD == "D") {
              leTransfert.setCodeDepart("Bewässerung PS")
            } else {
              leTransfert.setCodeArriver("Bewässerung PS")
            }
            break
          case "Arrossage CAD":
            if (AD == "D") {
              leTransfert.setCodeDepart("Bewässerung CAD")
            } else {
              leTransfert.setCodeArriver("Bewässerung CAD")
            }
            break
          case "Batiment":
            if (AD == "D") {
              leTransfert.setCodeDepart("Gebäude")
            } else {
              leTransfert.setCodeArriver("Gebäude")
            }
            break
        }
        const f = code.split(" ")
        if (f[0] == "Fosse") {
          if (AD == "D") {
            leTransfert.setCodeDepart("Gruben " + f[1])
          } else {
            leTransfert.setCodeArriver("Gruben " + f[1])
          }
        }
      } else {
        switch (code) {
          case "Bewässerung PS":
            if (AD == "D") {
              leTransfert.setCodeDepart("Arrossage PS")
            } else {
              leTransfert.setCodeArriver("Arrossage PS")
            }
            break
          case "Bewässerung CAD":
            if (AD == "D") {
              leTransfert.setCodeDepart("Arrossage CAD")
            } else {
              leTransfert.setCodeArriver("Arrossage CAD")
            }
            break
          case "Gebäude":
            if (AD == "D") {
              leTransfert.setCodeDepart("Batiment")
            } else {
              leTransfert.setCodeArriver("Batiment")
            }
            break
        }
        const f = code.split(" ")
        if (f[0] == "Gruben") {
          if (AD == "D") {
            leTransfert.setCodeDepart("Fosse " + f[1])
          } else {
            leTransfert.setCodeArriver("Fosse " + f[1])
          }
        }
      }
      return leTransfert
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Date", //0
            "Numéros de charge", //1
            "Laitier", //2
            "Employé", //3
            "Machine", //4
            "Cuvier", //5
            "Zone de départ", //6
            "Heure de départ", //7
            "Zone d'arrivée", //8
            "Heure d'arrivée" //9
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Datum", //0
            "Gießnummern", //1
            "Schlacke", //2
            "Mitarbeiter", //3
            "Maschine", //4
            "Kübel", //5
            "Uhrzeit Aufnahme", //6
            "Uhrzeit abkippen", //7
            "Lieferungs punkt", //8
            "Lieferungs zeit" //9
          ]
          break
      }
    },
    dateHeureFrancaise(date: string) {
      if (date == null) {
        return ""
      }
      const [laDate, temps] = date.split(" ")
      const [year, month, day] = laDate.split("-")
      const [heure, minute] = temps.split(":")
      if (year && month && day) {
        return `${day}/${month}/${year} ${heure}:${minute}`
      }
    },
    autTransfertLaitier() {
      return variables.autorisation.includes("Administration")
    }
  },
  created() {
    this.initialiser()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
          break
      }
    })
  }
})
